import { useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

function FranchiseDetailItem(props){
    const navigate=useNavigate();
    const temp=useState(props.item)
    const item=temp[0]
    console.log(item)
    const storeDetail=()=>{
        navigate("/uniqueStore",{
            state:{
                storeId:item.orgId
            }
        })
    }
    return(
        <>
        <tr>
    
        <td> 1 </td>
        <td onClick={storeDetail}>
        <Link>{item.orgName}</Link>
        </td>
        <td>
           {item.phone}
        </td>
        
        <td className="align-middle">
            <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
                <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    checked={item.status === "ACTIVE" ? true : false}

                />
            </div>
        </td>
        
        <td className="align-middle">
                    <div className="d-flex justify-content-center">
                    <span className="edit-icon-btn">
                        <FaEdit/>
                    </span>
                    {/* onClick={deleteItem} */}
                    <span className="delete-icon-btn" >
                        <FaTrash/>
                        </span>
                    </div>
                </td>
      </tr>
        </>
    )
}
export default FranchiseDetailItem