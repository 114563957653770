import { useEffect, useState } from "react";
import StoreItem from "./store-item";
import axios from "axios";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";
import {
  CATGORY_API_PATH,
  STORE_API_PATH,
} from "../../network/config/apiPaths";
import { useNavigate } from "react-router-dom";
import DeleteBanner from "../banners/crudOperations/deleteBanner";
import { updateMessage } from "../../redux/slice/toastSlice";
import { useDispatch, useSelector } from "react-redux";
import { deleteStatus } from "../../redux/slice/formUpdateSlice";
import { isCustomerSupport, isDsplayCrud, isEmployee, isFranchisLogin } from "../../utils/userRoles";
import { Search } from "semantic-ui-react";

function Stores() {
  const [storeResult, setStoreResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const showDeleteAlert = useSelector(deleteStatus);
  const [categoryResult, setCategoryResult] = useState([]);
  const dispatch = useDispatch();
  const [categoryId, setCategoryId] = useState("");
  const [resultList, setResultList] = useState([]);
  const [storeName, setStoreName] = useState("");
  const [locationName, setLocationName] = useState("");

  //pagination
  // const [totalCount, setTotalCount] = useState(0);
  // const [pageLimit, setPageLimit] = useState(20);
  // const [pageOffset, setPageOffset] = useState(0);
  // const [activePage, setActivePage] = useState(1);
  // const [pageCount, setPageCount] = useState(0);

  // const lastIndex=activePage*pageLimit;
  // const firstIndex=lastIndex-pageLimit;
  // const records=resultList.slice(firstIndex,lastIndex);
  // const npage=resultList && resultList.length>=0 && Math.ceil(resultList/pageLimit)
  // const numbers=[...Array(npage+1).keys()].slice(1)

  const navigate = useNavigate();
  useEffect(() => {
    StoreList();
    categoryApi();
  }, []);

  async function StoreList() {
    setIsLoading(true);

    let apiPath = API_CONFIG.API_HOST + STORE_API_PATH.STORE_LIST;
    if (isFranchisLogin()) {
      apiPath = apiPath + "?frId=" + localStorage.getItem("userId");
      console.log(localStorage.getItem("userId"))
    }

    const data = await axios.get(apiPath, {
      headers: API_HEADERS,
    });

    setIsLoading(false);
    if (data.data.result !== null) {
      setStoreResult(data.data.result);
      setResultList(data.data.result);
    }
  }

  const categoryApi = async () => {
    let data = await axios.get(
      API_CONFIG.API_HOST + CATGORY_API_PATH.LIST_PATH,
      {
        headers: API_HEADERS,
      }
    );

    setCategoryResult(data.data.result);
  };

  const storeNameHandleChange = (e) => {
    setStoreName(e.target.value);
    let searchterm = e.target.value;

    console.log("category", categoryId);

    if (searchterm !== "" && categoryId !== "") {
      let data = resultList.filter((item) => {
        if (item.name.toLowerCase().includes(searchterm.toLowerCase())) {
          return item;
        }
      });
      setResultList(data);
    } else if (categoryId === "" && searchterm !== "") {
      let data = storeResult.filter((item) => {
        if (item.name.toLowerCase().includes(searchterm.toLowerCase())) {
          return item;
        }
      });
      setResultList(data);
    } else {
      setResultList(storeResult);
    }
  };

  const locationNameHandleChange = (e) => {
    let locationterm = e.target.value;
    setLocationName(e.target.value);
    console.log(locationterm);
    if (categoryId === "" && locationterm !== "") {
      let data = storeResult.filter((item) => {
        if (
          item.location_name.toLowerCase().includes(locationterm.toLowerCase())
        ) {
          return item;
        }
      });
      setResultList(data);
    } else if (categoryId !== "" && locationterm !== "") {
      let data = resultList.filter((item) => {
        if (
          item.location_name.toLowerCase().includes(locationterm.toLowerCase())
        ) {
          return item;
        }
      });
      setResultList(data);
    } else {
      setResultList(storeResult);
    }
  };

  const handleChange = (e) => {
    setCategoryId(e.target.value);
    let searchitem = e.target.value;
    console.log("id...", searchitem);
    if (searchitem === "" || searchitem === "--select category--") {
      setResultList(storeResult);
    } else {
      let res = storeResult.filter((item) => {
        if (searchitem === item.categoryName) {
          return item;
        }
      });
      console.log("item", res);
      setResultList(res);
    }
    console.log("main result...", resultList);
  };

  const categoryList = () => {
    return (
      <>
        {categoryResult && categoryResult.length > 0 && (
          <>
            {categoryResult.map((obj, i) => {
              return (
                <option value={obj.name} key={obj}>
                  {obj.name}
                </option>
              );
            })}
          </>
        )}
      </>
    );
  };

  const onSubmit = () => {
    dispatch(
      updateMessage({
        display: true,
        message: "Record deleted",
      })
    );
    // setBannerList([]);
    StoreList();

    // loadData();
  };

  {
  }
  return (
    <>
      {showDeleteAlert && (
        <DeleteBanner
          onSubmit={() => {
            onSubmit();
          }}
        />
      )}
      <main className="container-fluid dashboard">
        
        <div className="row m-3">
          
          <div className="col-3">
            <input
              value={locationName}
              placeholder="Search Location"
              onChange={locationNameHandleChange}
              className="form-control"
            />
          </div>
          <div className="col-3">
            <input
              value={storeName}
              placeholder="Search by Area"
              onChange={storeNameHandleChange}
              className="form-control"
            />
          </div>

        </div>
        <div className="row m-3">
        <div className="col-3">
            <select
              value={categoryId}
              onChange={handleChange}
              className="form-control"
            >
              <option>{"--select category--"}</option>
              {categoryList()}
            </select>
          </div>
          <div className="col-3">
            <input
              value={storeName}
              placeholder="Search by Store Name"
              onChange={storeNameHandleChange}
              className="form-control"
            />
          </div>
        </div>
        <div className="row m-3">
          <div className="row justify-content-around tble">
            <div className="col-6">
              <h3 className="dashboard-title">Store</h3>
            </div>
            <div className="mr-auto col-6 text-end">
              {(!isFranchisLogin() && !isCustomerSupport()) && (
                <button
                  type="button"
                  className="mr-auto btn btn-purple-bg"
                  onClick={() => navigate("/addStore")}
                >
                  Add New
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="row m-3">
          <div className="col-md-12 card-1">
            <table className="table table-hover align-middle">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Store ID</th>
                  <th Scope="col">Store Category</th>
                  <th scope="col">Store Name</th>
                  <th scope="col">Location</th>
                  <th scope="col">Contact Name</th>
                  <th scope="col">Contact No</th>
                  <th scope="col">Radius</th>
                  {(!isFranchisLogin() && !isCustomerSupport()) && <><th scope="col">Status</th></>}

                  {isDsplayCrud() && <th scope="col">Actions</th>}
                </tr>
              </thead>

              <tbody>
                {isLoading ? (
                  <>Loading</>
                ) : resultList && resultList.length >= 0 ? (
                  <>
                    {resultList.map((obj, i) => {
                      return <StoreItem item={obj} key={obj.id} />;
                    })}
                  </>
                ) : (
                  <>No data</>
                )}
              </tbody>
            </table>
          </div>
          {/* <div className="col-12">
            <ul className="pagination">
              {
                numbers.map((n,i)=>{
                  <li className="page-item" key={i}>
                    <a href='#' className="page-link" onClick={changeCpage}>{n}</a>
                  </li>
                })
              }
            </ul>
          </div> */}
        </div>
      </main>
      {console.log("store", storeResult)}
      {console.log("result", resultList)}
    </>
  );
  //   const changeCpage=()=>{

  //   }
}

export default Stores;
