import { FaEdit, FaTag, FaTrash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import {
  displayRemoveAlert,
  updateFormInfo,
} from "../../redux/slice/formUpdateSlice";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { isCustomerSupport, isDsplayCrud, isEmployee, isFranchisLogin } from "../../utils/userRoles";
import { axiosApi } from "../../network/service/config/AaxiosUtil";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";
import { STORE_API_PATH } from "../../network/config/apiPaths";
import { updateMessage } from "../../redux/slice/toastSlice";

function StoreItem(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [product, setItem] = useState(props.item);
  const [itemStatus, setitemStatus] = useState(product.status);

  const editItem = () => {
    // dispatch(updateFormInfo({ displayForm: false, formData: { product } }));
    navigate("/updateStore", { state: { product } });
  };

  const editTags = () => {
    dispatch(updateFormInfo({ displayForm: false, formData: { product } }));
    navigate("/stags");
  };

  const listItem = () => {
    // dispatch(updateFormInfo({ displayForm: false, formData: { product } }));
    navigate("/uniqueStore", {
      state: {
        storeId: product.id,
        storeName: product.name,
        storeAddress: product.city,
      },
    });
  };

  const onStatusChange = async () => {
    let status = "YES";
    if (product.status == "NO") {
      status = "YES";
    } else {
      status = "NO";
    }

    let requestBody = {
      status: status,
      id: product.id,
    };

    await axiosApi
      .post(API_CONFIG.API_HOST + STORE_API_PATH.CREATE_STORE, requestBody, {
        API_HEADERS,
      })
      .then((response) => {
        console.log("STORE UPODATE", response);
        if (response.status === 200 && response.data.status === true) {
          setItem(product, (product.status = status));
          setitemStatus(status);
        } else {
          dispatch(
            updateMessage({
              display: true,
              message: "Error While updating info",
            })
          );
        }
      });

    // await dispatch(createUpdateAction(requestBody)).then((response) => {
    //   if (response.status == true) {
    //     setItem(product, (product.status = status));
    //     setitemStatus(status);
    //   } else {
    //     dispatch(
    //       updateMessage({
    //         display: true,
    //         message: "Error While updating info",
    //       })
    //     );
    //   }
    // });
  };

  const deleteItem = () => {
    dispatch(
      displayRemoveAlert({
        displayRemove: true,
        formData: { item: product },
        type: "store",
      })
    );
  };
  return (
    <>
      <tr>
        <td>{product.id}</td>
        {/* <td>{product.is_veg === "NO" ? "Non-veg" : "Veg"}</td> */}
        <td>{product.categoryName}</td>
        <td onClick={() => listItem()}>
          <Link> {product.name}</Link>
        </td>
        <td>{product.location_name}</td>
        <td>{product.contact_name}</td>
        <td>
          {product.contact_one} & <br />
          {product.contact_two}
        </td>

        <td>{product.radius}</td>
        {(!isFranchisLogin() && !isCustomerSupport()) && <>
          <td className="align-middle">
            <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                onChange={onStatusChange}
                checked={itemStatus === "YES" ? true : false}
              />
            </div>
          </td>
        </>}


        {isDsplayCrud() && (
          <>
            <td className="align-middle">
              <div className="d-flex justify-content-center">
                <span className="edit-icon-btn" onClick={() => editItem()}>
                  <FaEdit />
                </span>
                <span className="delete-icon-btn" onClick={deleteItem}>
                  <FaTrash />
                </span>
                <span
                  className="delete-icon-btn"
                  onClick={() => {
                    editTags();
                  }}
                >
                  <FaTag />
                </span>
              </div>
            </td>
          </>
        )}
      </tr>
    </>
  );
}

export default StoreItem;
