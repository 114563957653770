import { FaEdit, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";

function CustomerItem(props) {
  return (
    <>
      <tr>
        <td> {props.id} </td>
        <td>
          <Link to="/customerOrders">{props.name}</Link>
        </td>
        <td> {props.phone} </td>
        <td> {props.city} </td>
        <td> {props.refferalCode===""?"Direct":"Referal"} </td>
       

        <td className="align-middle">
          <div class="form-check form-switch col-lg-12 d-flex justify-content-center">

            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              checked={props.status === "ACTIVE" ? true : false}

            />

          </div>
        </td>
      </tr>
    </>
  );
}

export default CustomerItem;
