import { FaEdit, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";

function CustomerOrderItem(props) {
  return (
    <>
      <tr>
        <td> {props.id} </td>
        <td>
         {props.date}
        </td>
        <td> {props.orderNo} </td>
        <td>{props.orderAmount}</td>
        <td> {props.receiversName} </td>
        
        <td>{props.city}</td>

        <td>{props.coupon}</td>

        <td className="align-middle">
          <div class="form-check form-switch col-lg-12 d-flex justify-content-center">

            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              checked={props.status === "ACTIVE" ? true : false}

            />

          </div>
        </td>
        <td className="align-middle">
          <div className="d-flex justify-content-center">

          <span className="edit-icon-btn">
              <FaEdit />
            </span>

            <span className="delete-icon-btn">
              <FaTrash />
            </span>

          </div>
        </td>
      </tr>
    </>
  );
}

export default CustomerOrderItem;
