import { useState } from "react";
import { useLocation } from "react-router-dom";
import DeliveryOrderItem from "./delivery-order-item";

function Deliveryorder(){
    const location=useLocation();
    const name=location.state !== null ?location.state.dbName:"";
    const city=location.state !== null?location.state.city:""
    const[selectMonth,setMonth]=useState("");
    const month=[
      {id:1,name:"JAN"},
      {id:2,name:"FEB"},
      {id:3,name:"MARCH"},
      {id:4,name:"APRIL"},
      {id:5,name:"MAY"},
      {id:6,name:"JUNE"},
      {id:7,name:"JULY"},
      {id:8,name:"AUG"},
      {id:9,name:"SEP"},
      {id:10,name:"OCT"},
      {id:11,name:"NOV"},
      {id:12,name:"DEC"},
    ]
    return(
        <>
        <main className="container-fluid dashboard">
        <div className="row m-3">
          <div className="row justify-content-around tble">
            <div className="col-6">
              <h3 className="dashboard-title">{name}</h3>
              <h4 className="dashboard-title">{city}</h4>
            </div>
            <div className="mr-auto col-6 text-end">
              {/* <button
                type="button"
                className="mr-auto btn btn-purple-bg"
                onClick={() => {
                  dispatch(
                    updateFormInfo({
                      displayForm: true,
                      formData: {},
                    })
                  );
                }}
              >
                + Add Category
              </button> */}
            </div>
          </div>
        </div>
        <div className='row m-3'>
        <div className='col-2'>
        <label>Month</label>
          <select value={selectMonth} className='form-control'>
            <option>{"< FEB >"}</option>
            {
              month.map(item=>{
                return(
                  <option value={item.id}>{item.name}</option>
                )
              })
            }
          </select>
        </div>
        <div className='col-2'>

          <label>From</label>
          <input type="date" className='form-control'/>
            
          
        </div>
        <div className='col-2'>

          <label>To</label>
          <input type="date" className='form-control'/>
            
          
        </div>
       </div>
        <div className="row m-3">
          

          <div className="col-md-12 card-1">
            <table className="table table-hover align-middle">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Sno</th>
                  <th Scope="col">Date & Time</th>
                  <th scope="col">Order No</th>
                  <th scope="col">Order Amount</th>
                  <th scope="col">Customer Name</th>
                  <th scope="col">D.Location</th>
                  <th scope="col">Status</th>
                  <th scope="col">Payment</th>
                  
                </tr>
              </thead>
              
                <tbody>
                  <DeliveryOrderItem/>
                </tbody>
              
            </table>
          </div>
        </div>
      </main>
        </>
    )
}
export default Deliveryorder