import axios from "axios";
import { useEffect, useState } from "react";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";
import { DASHBOARD_API_PATH } from "../../network/config/apiPaths";

function Dashboard() {
  const [result,setResult]=useState([]);
  useEffect(()=>{
    dashboardApi()
  },[])
  const dashboardApi=async ()=>{
      let data=await axios.get(API_CONFIG.API_HOST+DASHBOARD_API_PATH.LIST_PATH,{
        headers:API_HEADERS
      })
      console.log(data.data.result)
      setResult(data.data.result)
  }
  return (
    <>
      <main className="container-fluid dashboard">
        <div className="row m-3">
          <div className="col-3">
            <div className="dashboard-div d-flex flex-column justify-content-center">
                 Total Products <br/> {result.totalProducts} Nos
            </div>
          </div>
          <div className="col-3">
            <div className="dashboard-div d-flex flex-column justify-content-center">
                 Total Locations <br/> {result.totalLocations} Nos
            </div>
          </div>
          <div className="col-3">
            <div className="dashboard-div d-flex flex-column justify-content-center">
                 Total Stores <br/> {result.totalStoresCount} Nos
            </div>
          </div>
          <div className="col-3">
            <div className="dashboard-div d-flex flex-column justify-content-center">
                 Total Employees <br/> {result.totalEmployees} Nos
            </div>
          </div>
        </div>
        <div className="row m-3">
          <div className="col-3">
            <div className="dashboard-div d-flex flex-column justify-content-center">
                 Total Customers <br/> {result.totalCustomers} Nos
            </div>
          </div>
          <div className="col-3">
            <div className="dashboard-div d-flex flex-column justify-content-center">
                 Total Orders <br/> {result.totalOrders} Nos
            </div>
          </div>
          <div className="col-3">
            <div className="dashboard-div d-flex flex-column justify-content-center">
                 Total Payment <br/> {result.totalPayments} Nos
            </div>
          </div>
          <div className="col-3">
            <div className="dashboard-div d-flex flex-column justify-content-center">
                 Total App Amount <br/> {result.totalAppAmount} Nos
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Dashboard;
