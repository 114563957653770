import axios from "axios";
import { API_CONFIG, API_HEADERS } from "../../network/config/ApiConfig";
import { ORDER_API_PATH } from "../../network/config/apiPaths";
import OrdersItem from "./order-item";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Orders() {
  const [orderResult, setOrderResult] = useState([]);
  const [isLoading,setIsLoading]=useState(false)

  const navigate = useNavigate();
  useEffect(() => {
    OrderList();
  }, []);

  async function OrderList() {
    setIsLoading(true)
    const data = await axios.get(
      API_CONFIG.API_HOST + ORDER_API_PATH.ORDER_LIST,
      {
        headers: API_HEADERS,
      }
    );
    setIsLoading(false)
    if(data.data.result!==null)setOrderResult(data.data.result);
  }

  return (
    <>
        <main className="container-fluid dashboard">
          <div className="row m-3">
            <div className="row justify-content-around tble">
              <div className="col-6">
                <h3 className="dashboard-title">Orders</h3>
              </div>
              <div className="mr-auto col-6 text-end">
                {/* <button
                type="button"
                className="mr-auto btn btn-purple-bg"
                onClick={() => {
                 
                }}
              >
                + Add Category
              </button> */}
              </div>
            </div>
          </div>
          <div className="row m-3">
            <div className="col-md-3">
              <div className="form-group">
                <label>Search</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Enter customer mobile no"
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <select className="form-scrollbar form-control">
                  <option value="">{"--Select Category--"}</option>
                  <option>D.Boy Name</option>
                  <option>Status</option>
                  <option>Location</option>
                </select>
              </div>
            </div>

            <div className="col-md-12 card-1">
              <table className="table table-hover align-middle">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Store Name</th>
                    <th scope="col">Date & Time</th>
                    <th scope="col">Order Amount</th>
                    <th scope="col">Customer Name </th>
                    <th scope="col">D.Location </th>
                    <th scope="col">Coupons</th>
                    <th scope="col">Status </th>
                    <th scope="col">Payment Mode </th>
                  </tr>
                </thead>

                <tbody>
                  {
                    isLoading?(
                      <>Loading</>
                    ):
                    orderResult&&orderResult.length>0?
                    <>
                    {
                  orderResult.map((obj) => {
                    return <OrdersItem order={obj} key={obj.id} />;
                  })
                }
                  </> :(
                    <>No data</>
                  )
                  }
                </tbody>
              </table>
            </div>
          </div>
        </main>
      
    </>
  );
}
export default Orders;
