import { useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { displayRemoveAlert } from "../../redux/slice/formUpdateSlice";

function UserList(props) {
  const dispatch=useDispatch();
  const [product]=useState(props.item)
  const deleteItem = () =>{
    dispatch(displayRemoveAlert({ displayRemove: true, formData: { item:product },type:"users" }));
  }
  return (
    <>
      <tr>
        <td> {product.id} </td>
        <td>
          <Link to="/userProfile">{product.name}</Link>
        </td>
        <td> {product.phone} </td>
        <td> {product.roleName==="USER"?"CUSTOMER":product.roleName} </td>
        
        <td className="align-middle">
            <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
                <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    checked={product.status === "ACTIVE" ? true : false}

                />
            </div>
        </td>
        
        <td className="align-middle">
                    <div className="d-flex justify-content-center">
                    <span className="edit-icon-btn">
                        <FaEdit/>
                    </span>
                    <span className="delete-icon-btn" onClick={deleteItem}>
                        <FaTrash/>
                        </span>
                    </div>
                </td>
      </tr>
    </>
  );
}

export default UserList;
