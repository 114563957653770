import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function OrderItem(props) {
  const [order] = useState(props.item);
  const navigate = useNavigate();
  function Temp(status) {
    switch (status) {
      case "0":
        return <span style={{ color: "black" }}>Pending</span>;

      case "1":
        return <span style={{ color: "pinkAccent" }}>Accepted</span>;

      case "2":
        return <span style={{ color: "blueAccent" }}>Assigned</span>;

      case "3":
        return <span style={{ color: "yellow" }}>Picked Up</span>;
      case "4":
        return <span style={{ color: "green" }}>Delivered</span>;
      case "5":
        return <span style={{ color: "green" }}>Delivered</span>;
      case "6":
        return <span style={{ color: "red" }}>Cancelled</span>;
      case "7":
        return <span style={{ color: "black" }}>Rejected</span>;
      case "8":
        return (
          <span style={{ color: "0xffcc0d0d" }}>Order Cancel Request</span>
        );
    }
  }

  const orderDetailFynctionality = () => {
    //dispatch(updateFormInfo({ displayForm: false, formData: { order } }));

    navigate("/orderDetails", { state: { order: order, from: "store" } });
  };

  return (
    <tr>
      <td>{order.id}</td>
      <td>{order.created_at}</td>
      {/* .substring(0, 10) */}

      <td onClick={() => orderDetailFynctionality()}>
        <Link>#EP0{order.order_id}</Link>
      </td>
      <td>{order.total_price}</td>
      <td>{order.customerName}</td>
      <td>{order.landmark}</td>
      <td>{order.coupon_code}</td>
      <td>{Temp(order.status)}</td>
      <td>{order.dbName}</td>
    </tr>
  );
}

export default OrderItem;
