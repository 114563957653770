import { useLocation } from "react-router-dom"
import FranchiseDetailItem from "./franchise-detail-item"

function FranchiseDetails(){
    const location=useLocation();
    const item=location.state != null  ? location.state.name : ""
    console.log("franchise",location.state)
    return(
        <>
         <main className="container-fluid dashboard">
      <div className="row m-3">
        <div className="row justify-content-around tble">
          <div className="col-6">
            <h3 className="dashboard-title">{item.name}</h3>
            <h3 className="dashboard-title">Radius : {item.radius}</h3>
          </div>
          <div className="mr-auto col-6 text-end">
            {/* <button
              type="button"
              className="mr-auto btn btn-purple-bg"
             
            >
              + Add
            </button> */}
          </div>
        </div>
      </div>
      <div className="row m-3">
        <div className="col-md-3">
          <div className="form-group">
            <label>Search</label>
            <input
              type="number"
              name="name"
              className="form-control"
             
              placeholder="Enter Phone No"
            />
          </div>
        </div>
        
        <div className="col-md-12 card-1">
          <table className="table table-hover align-middle">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Sno</th>
                <th scope="col">Store Name</th>
                <th scope="col">Contact no</th>
               
                <th scope="col">Status</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>

            <tbody>
              {/* {
                // isLoading ? (
                //   <>Loading</>
                // ):
                filterList&&filterList.length>0?
              filterList.map((obj) => {
               
              }):(
                <>No data</>
              )
            } */}
            <FranchiseDetailItem item={item}/>
            </tbody>
          </table>
        </div>
      </div>
    </main>
        </>
    )
}

export default FranchiseDetails