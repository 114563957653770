import React, { useEffect, useState } from 'react'
import OrderItem from './unique store items/Order-Item'
import axios from 'axios';
import { API_CONFIG, API_HEADERS } from '../../../network/config/ApiConfig';
import { STORE_API_PATH } from '../../../network/config/apiPaths';

function UniqueStoreOrders(props) {
  
  const[order] = useState(props.id);
  const[resultList,setResultList]=useState([])
  const[isLoading,setIsLoading]=useState(false);
  const[selectMonth,setMonth]=useState("");
  const month=[
    {id:1,name:"JAN"},
    {id:2,name:"FEB"},
    {id:3,name:"MARCH"},
    {id:4,name:"APRIL"},
    {id:5,name:"MAY"},
    {id:6,name:"JUNE"},
    {id:7,name:"JULY"},
    {id:8,name:"AUG"},
    {id:9,name:"SEP"},
    {id:10,name:"OCT"},
    {id:11,name:"NOV"},
    {id:12,name:"DEC"},
  ]

 useEffect(()=>{
  StoreOrdersApi()
 },[])


  const StoreOrdersApi = async() =>{
    setIsLoading(true)
    let data=await axios.get(API_CONFIG.API_HOST+STORE_API_PATH.UNIQUE_STORE_ORDERS+order,{
      headers:API_HEADERS
    })
    console.log(data.data.result)
    setIsLoading(false)
    if(data.data.result!==null)setResultList(data.data.result)
  }

  return (
    <>
       <div className='row mb-3'>
        <div className='col-2'>
        <label>Month</label>
          <select value={selectMonth} className='form-control'>
            <option>{"--select month--"}</option>
            {
              month.map(item=>{
                return(
                  <option value={item.id}>{item.name}</option>
                )
              })
            }
          </select>
        </div>
        <div className='col-2'>

          <label>From</label>
          <input type="date" className='form-control'/>
            
          
        </div>
        <div className='col-2'>

          <label>To</label>
          <input type="date" className='form-control'/>
            
          
        </div>
       </div>
   
       <div className="row mb-3">
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
             100 <br/> Orders Recieved
      </div>
    </div>
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
      100 <br/> Orders Accepted
      </div>
    </div>
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
      50 <br/> Orders Shipped
      </div>
    </div>
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
      30 <br/> Orders Delivered
      </div>
    </div>
    <div className="col-2">
      <div className="dashboard-div d-flex flex-column justify-content-center">
      0 <br/> Orders Cancelled
      </div>
    </div>
  </div>
       <div className="row">
       <div className='col-3 mb-3'>
          <input className='form-control' placeholder='search by phone number'/>
        </div>

         <div className="col-md-12 card-1">
           <table className="table table-hover align-middle">
             <thead className="thead-dark">
               <tr>
                 <th scope="col">ID</th>
                 <th scope="col">Date</th>
                 <th scope="col">Order No</th>
                 <th scope="col">Order Amount</th>
                 <th scope="col">Customer Name</th>
                 <th scope="col">D.Location</th>
                 <th scope="col">Coupon</th>
    
                 <th scope="col">Status</th>
                 <th scope="col">D Boy</th>
                
               </tr>
             </thead>
             
               <tbody>
                {
                  isLoading?(
                    <>Loading</>
                  ):
                  resultList && resultList.length >0 ?
                   resultList.map((obj,i)=>{
                    return(
                      <OrderItem item={obj}/>
                    )
                  }):(
                    <>No data</>
                  )
                }
               </tbody>
             
           </table>
         </div>
       </div>
     
    </>
  )
}

export default UniqueStoreOrders